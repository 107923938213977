import React from 'react'

import SiteHeadMetadata from 'components/SiteHeadMetadata'

const Unavailable = () => (
  <>
    <SiteHeadMetadata title="Unavailable" />
    <h1>Online Only</h1>
    <p>Can't access this resource offline</p>
  </>
)

export default Unavailable
